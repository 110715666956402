$(function () {

  // infinite scroll
  if ($('.infinite-scroll .pagination').length) {
    $(window).scroll(function () {
      var url = $('.infinite-scroll .pagination .next_page').attr('href');
      if (url && $(window).scrollTop() > $(document).height() - $(window).height() - 3000) {
        $('.pagination').text("Please Wait...");
        return $.getScript(url);
      }
    });
  }

  $('.side-collapse .header .close-btn').on('click', function () {
    $('.side-collapse').removeClass('open').addClass('close')
  })

  $('.navbar-toggle-sidebar ').on('click', function () {
    $('.side-collapse').removeClass('close').addClass('open')
  })

  $(document).scroll(function () {
    //Show element after user scrolls 800px
    var y = $(document).height();
    var x = $(document).height() * 0.5;
    if (y > x) {
      $('#book_consultation').fadeIn();
    } else {
      $('#book_consultation').fadeOut();
    }
  });

  // book design Consultation
  setTimeout(function () {
    $(".box-notify").fadeIn('slow');
  }, 3500);
  setTimeout(function () {
    $(".box-notify").fadeOut('slow');
  }, 9500);

  $(window).scroll(function () {
    if ($(this).scrollTop() > 140) {
      $('.posts-filter').removeClass('d-none');
      $('.navbar-padding-adjust').addClass('pb-0')
    }
    else {
      $('.posts-filter').addClass('d-none');
      $('.navbar-padding-adjust').removeClass('pb-0')
    }
  });

  // dismiss flash alerts
  $(".flash-alert").delay(5000).fadeOut(800);

})